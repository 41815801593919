<template>
  <el-container class="SubLayout">
    <!-- 侧边导航栏 -->
    <el-aside class="SubSider">
      <div class="SubIcon">
        <img src="../../assets/images/终端升级.png" />
        <label>终端升级管理</label>
      </div>
      <NavMenu
        :defaultActive="defaultActive"
        :navMenus="navList"
        @select="handleGo"
      ></NavMenu>
    </el-aside>
    <!-- 主页面 -->
    <el-main class="SubContent">
      <PageView :include="include" :max="100"></PageView>
    </el-main>
  </el-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import PageView from '@/layout/PageView.vue';
import NavMenu from '@/layout/NavMenu';
import { hasPerms } from '@/utils/auth';
import aeepAlive from "@/router/aeepAlive";

import * as UpgradeManagement from '@/router/modules/UpgradeManagement';

export default {
  // `name`与路由保持一致
  name: 'UpgradeManagement',
  components: {
    NavMenu,
    PageView
  },
  data() {
    return {
      // 默认路由
      defaultSub: null,
      parentName: 'UpgradeManagement'
    };
  },
  watch: {
    '$route': {
      immediate: true,
      handler(val, oldVal) {
        const { navData, parentName } = this;
        const currentMenu = navData.find(obj => obj.name === val.name);
        if (currentMenu) {
          this.defaultSub = currentMenu;
        } else if (parentName === val.name) {
          if (this.defaultSub) {
            this.$router.push(this.defaultSub.path);
          } else {
            this.$router.push(navData[0].path);
          }
        }
      }
    }
  },

  computed: {
    // ...mapState(['cachePages']),

    navData() {
      return Object.values(UpgradeManagement);
    },
    defaultActive() {
      const { navData, defaultSub } = this;
      return defaultSub ? defaultSub.name : navData[0].name;
    },
    navList() {
      return this.navData.filter(item => hasPerms(item.meta.permId));
    },
    include() {
      // return this.cachePages.map(menu => menu.name);
      return aeepAlive;
    }
  },
  methods: {
    ...mapMutations(['setCachePages']),
    handleGo(menu) {
      this.$router.push(menu.path);
      this.$emit('select', menu);
    },
  },
  beforeDestroy() {
    // 从store `cachePages` 清除所有子页面
    // let { cachePages, navData } = this;
    // cachePages = cachePages
    //   .filter(menu => !navData.some(nav => nav.name === menu.name));
    // this.setCachePages(cachePages);
  },
}
</script>
<style lang="scss" scoped>
.SubLayout {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}
.SubSider {
  position: relative;
  // 重置el-aside宽度
  width: 240px !important;
  height: 100%;
  background: #ffffff;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.SubContent {
  position: relative;
  width: calc(100% - 200px);
  height: 100%;
  background: #ffffff;
  margin-left: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.SubIcon {
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  margin: 30px 0;
  & > img {
    width: 50px;
    margin: 10px auto;
  }
}

::v-deep .NavMenu {
  height: auto;
}

::v-deep .el-menu {
  background: #ffffff;
  border-right: unset;
  .el-menu-item,
  .el-submenu__title {
    color: #333333;
    &:hover {
      background-color: #dce8fc;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  // 激活的`el-menu-item1
  .is-active:not(.is-opened) {
    color: #4278c9;
    background-color: #dce8fc;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
}
</style>